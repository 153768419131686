
import * as imagekitRuntime$s0DTDmaFcl from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/imagekit'
import * as noneRuntime$AlJCu1LSrU from '/opt/buildhome/repo/node_modules/@nuxt/image/dist/runtime/providers/none'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "none",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['imagekit']: { provider: imagekitRuntime$s0DTDmaFcl, defaults: {"baseURL":"https://ik.imagekit.io/vx8pguvryy"} },
  ['none']: { provider: noneRuntime$AlJCu1LSrU, defaults: {} }
}
        